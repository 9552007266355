import React from "react";
import MainScreen from "./screens/MainScreen";

export default () => (<MainScreen />);

// export default () => (
//   <Provider store={store}>
//     <BrowserRouter>
//       <Header />
//       <Routers />
//     </BrowserRouter>
//   </Provider>
// );
